import { FC } from "react";

interface CoBrandLogoProps {
    className?: string;
    logoUrl: string;
}

const CoBrandLogo: FC<CoBrandLogoProps> = (props: CoBrandLogoProps) => {
    return <img className={props.className || ""} src={props.logoUrl} alt="Emovid CoBrandLogo" style={{ maxHeight: "26px" }} />;
};

export default CoBrandLogo;
