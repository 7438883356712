import { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { isDesktop, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton } from "@mui/material";
import { InlineWidget } from "react-calendly";
import { Paths, ROUTES_WITH_FOOTER_ENABLED, ROUTES_WITH_ID_AND_FOOTER_ENABLED } from "./AppRoutes";
import { AuthContext } from "../Contexts/AuthContext";
import { isWhitelistedUser } from "./GuardedRoute";
import { isNativePlatform, openURLInBrowser, pushTagEvent } from "./Helper";

interface FooterProps {
    children?: React.ReactNode;
    version?: string;
}

const Links = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #999;
    :hover {
        color: white;
    }
`;

const Footer: FC<FooterProps> = (props: FooterProps) => {
    let location = useLocation();
    const authContext = useContext(AuthContext);
    const [showFooter, setShowFooter] = useState<boolean>(false);
    const [isScheduleDemoDialogOpen, setIsScheduleDemoDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        let enabledPath = ROUTES_WITH_FOOTER_ENABLED.includes(location.pathname);
        ROUTES_WITH_ID_AND_FOOTER_ENABLED.forEach(route => {
            let routeWithoutId = route.split(":")[0];
            if ((location.pathname || "").length >= routeWithoutId.length && location.pathname.indexOf(routeWithoutId) >= 0) enabledPath = true;
        });
        if (isNativePlatform() && !authContext.user) {
            enabledPath = false;
        }
        setShowFooter(enabledPath);
    }, [location]);

    const scheduleDemoClicked = () => {
        setIsScheduleDemoDialogOpen(true);
        pushTagEvent("e_footer_click", { link_name: "scheduleDemo" });
    };

    const handleLinkClick = (linkName: string, path: string = "") => {
        pushTagEvent("e_footer_click", { link_name: linkName });
        openURLInBrowser(path);
    };

    if (showFooter)
        return (
            <footer className="footer bg-dark">
                <div className="row container m-auto pt-5 pb-5">
                    <div className={"col-md-6 col-sm-12 text-white " + (isDesktop || isTablet ? "pt-5" : "text-center")}>
                        <div className="d-flex">
                            <img className="logo-footer" alt="" src="/images/logo-footer.svg" />
                            <div className="vertical-divider ms-2 me-2"></div>
                            <div className="footer-logo-text">
                                More than words<sup style={{ fontSize: "0.6rem" }}>TM</sup>
                            </div>
                        </div>
                        <div className="mt-4 text-gray">
                            Emovid is the world's first multimodal communication platform - built for business. Emovid provides a cross-platform online service that lets you record
                            a video from anywhere and have it delivered in multi-modal form (video + audio + text + translation) to anyone, regardless of their platform or device.
                        </div>
                        {/* <h3 className="text-primary text-large mb-3">Join our newsletter</h3>
                        <div className="text-white mb-2">Get the latest Emovid news and updates</div>
                        <div className="d-flex">
                            <input type="email" className="form-control unset-border-radius" placeholder="Enter your email" />
                            <button className="btn btn-primary ms-2">Submit</button>
                        </div> */}
                        <div className="mt-5">
                            {/* <a className="btn btn-link">
                                <i className="fa-brands fa-2x fa-facebook-f"></i>
                            </a>
                            <a className="btn btn-link">
                                <i className="fa-brands fa-2x fa-instagram"></i>
                            </a>
                            <a className="btn btn-link">
                                <i className="fa-brands fa-2x fa-twitter"></i>
                            </a> */}
                            <a
                                href="https://www.linkedin.com/company/emovid/"
                                target="_blank"
                                className="btn btn-link text-white ps-0 pe-0"
                                onClick={() => handleLinkClick("linkedin_follow")}
                            >
                                <div className="d-flex" style={{ lineHeight: "32px" }}>
                                    Follow us on &nbsp;<i className="fa-brands fa-2x fa-linkedin"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 pt-5">
                        <div className={"d-flex flex-wrap justify-content-around " + (isDesktop || isTablet ? "ps-5" : "")}>
                            <div className={isDesktop || isTablet ? "" : "pb-5"}>
                                <div className="fw-bold pb-3">PRODUCT</div>
                                <Links>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("pricing")} to={Paths.pricing}>
                                            Pricing
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("howItWorks")} to={Paths.howItWorks}>
                                            How it Works
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("useCases")} to={Paths.useCases}>
                                            Use Cases
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("usageGuide")} to={Paths.usageGuide}>
                                            AI Usage Guide
                                        </StyledLink>
                                    </div>
                                    {isWhitelistedUser(authContext) && (
                                        <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                            <StyledLink onClick={() => handleLinkClick("resources")} to={Paths.resources}>
                                                Resources
                                            </StyledLink>
                                        </div>
                                    )}
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <button className="btn btn-link text-gray p-0" onClick={scheduleDemoClicked}>
                                            Schedule a Demo
                                        </button>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("testimonials")} to={`${Paths.testimonials}`.replace(":testimonialId", "jeffrey-hayzelett")}>
                                            Testimonials
                                        </StyledLink>
                                    </div>
                                    {authContext.user?.userId && (
                                        <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                            <StyledLink onClick={() => handleLinkClick("sendFeedback")} to={Paths.sendFeedback}>
                                                Share Feedback
                                            </StyledLink>
                                        </div>
                                    )}
                                </Links>
                            </div>
                            <div className={isDesktop || isTablet ? "" : "pb-5"}>
                                <div className="fw-bold pb-3">COMPANY</div>
                                <Links>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("contactSales")} to={Paths.contactSales}>
                                            Contact Us
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("press")} to={Paths.press}>
                                            Press
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("getSupport")} to={Paths.getSupport}>
                                            Support
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("privacyPolicy", Paths.privacyPolicy)} target="_blank" to={Paths.privacyPolicy}>
                                            Privacy
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("terms", Paths.terms)} target="_blank" to={Paths.terms}>
                                            Terms
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("aboutUs", Paths.aboutUs)} to={Paths.aboutUs}>
                                            About Us
                                        </StyledLink>
                                    </div>
                                    <div className="d-block text-white ps-0 pe-0 pt-1 pb-1 mt-1">
                                        <StyledLink onClick={() => handleLinkClick("affiliate", Paths.affiliate)} target="_blank" to={Paths.affiliate}>
                                            Affiliate
                                        </StyledLink>
                                    </div>
                                </Links>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row container m-auto">
                    <div className="col">
                        <div
                            className={"pt-4 d-flex flex-wrap " + (isDesktop || isTablet ? "justify-content-between pb-5" : "justify-content-center pb-4")}
                            style={{ borderTop: "1px solid #777" }}
                        >
                            <div className="text-gray text-small mb-3">Copyright © 2023 - 2025 Emovid Inc., All Rights Reserved.</div>
                            <div className="text-gray text-small mb-3">{props.version}</div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={isScheduleDemoDialogOpen}
                    className="more-popup overflow-hidden"
                    maxWidth={false}
                    fullWidth={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={() => setIsScheduleDemoDialogOpen(false)}
                >
                    <IconButton
                        className={"close-icon " + (isDesktop || isTablet ? "text-white" : "text-dark")}
                        aria-label="Close"
                        onClick={() => setIsScheduleDemoDialogOpen(false)}
                        style={{ right: "20px" }}
                    >
                        <CloseIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <InlineWidget url="https://calendly.com/d/cpqp-dr8-fd9/guided-product-walk-through-with-co-founder" />
                </Dialog>
            </footer>
        );
    else return null;
};

export default Footer;
