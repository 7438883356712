import { FC, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { Avatar, Dialog, Divider, IconButton, ListItemIcon, MenuItem, MenuList } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import { Link } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import { AuthContext } from "../../Contexts/AuthContext";
import MenuToggle from "./MenuToggle";
import Popup from "../../Common/Popup";
import { Paths } from "../../Common/AppRoutes";
import CreateEmovidButton from "../../Common/CreateEmovidButton";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import NotificationsIconButton from "./NotificationsIconButton";
import Login from "../Login/Login";
import Logo from "./Logo";
import CreatedVideosIcon from "../../Icons/CreatedVideosIcon";
import ContactSalesIcon from "../../Icons/ContactSalesIcon";
import { deleteStorageItem, isNativePlatform, pushContactUsEvent, setStorageItem } from "../../Common/Helper";
import { SHOULD_WHITELIST_USER_AFTER_LOGIN_KEY } from "../../Common/Constants";
import { isDesktop, isTablet } from "react-device-detect";
import CoBrandLogo from "./CoBrandLogo";

const NavLinksContainer = styled.div`
    height: 100%;
`;

const LinksWrapper = styled.ul`
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    z-index: 1100;
    overflow: auto;
`;

const LinkItem = styled.li`
    padding: 0.5em 0.3em;
    font-size: 1.5rem;
    color: #222;
    display: flex;
    justify-content: center;
    transition: all 220ms ease-in-out;
`;

interface MobileNavLinksProps {
    theme?: string;
    hideCreateEmovidButtonInMenu?: boolean;
    disableSignup?: boolean;
    showTrialExpiredWarning?: boolean;
    coBrandLogo?: string;
    coBrandNav?: string;
    hideLoggedInUserCoBrandLogo?: boolean;
    hasCoBrandLogoUrl?: boolean;
}

const MobileNavLinks: FC<MobileNavLinksProps> = (props: MobileNavLinksProps) => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoginOpen, setLoginOpen] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isScheduleDemoDialogOpen, setIsScheduleDemoDialogOpen] = useState<boolean>(false);
    const location = useLocation();
    const isValidateMFAPath = location.pathname.includes(Paths.validateMFA);
    const [disableBackdropDismiss, setDisableBackdropDismiss] = useState<boolean>(false);

    const signInClicked = () => {
        deleteStorageItem(SHOULD_WHITELIST_USER_AFTER_LOGIN_KEY);
        setOpen(false);
        setLoginOpen(true);
    };

    const dashboardClicked = () => {
        setOpen(false);
        navigate(Paths.dashboard);
    };

    const myAccountClicked = () => {
        setOpen(false);
        navigate(Paths.myAccount);
    };

    const createdVideosClicked = () => {
        setOpen(false);
        navigate(Paths.createdVideos);
    };

    const getSupportClicked = () => {
        setOpen(false);
        navigate(Paths.getSupport);
    };

    const pricingClicked = () => {
        setOpen(false);
        navigate(Paths.pricing);
    };

    const salesClicked = () => {
        pushContactUsEvent("mobile_nav_bar");
        setOpen(false);
        navigate(Paths.contactSales);
    };

    const aboutUsClicked = () => {
        setOpen(false);
        navigate(Paths.aboutUs);
    };

    const pressClicked = () => {
        setOpen(false);
        navigate(Paths.press);
    };

    const sendFeedbackClicked = () => {
        setOpen(false);
        navigate(Paths.sendFeedback);
    };

    const homeClicked = () => {
        setOpen(false);
        navigate(Paths.landing + "?show_home=true");
    };

    const loginComplete = (didSignup = true) => {
        setLoginOpen(false);
        setDisableBackdropDismiss(false);
        setStorageItem("waitlist_signup", "true");
        if (window.location.pathname === "/" && didSignup) window.location.reload();
        if (sessionStorage.getItem("promo_code") && !isWhitelistedUser(authContext)) navigate(Paths.landing);
    };

    const howItWorksClicked = () => {
        setOpen(false);
        navigate(Paths.howItWorks);
    };

    const useCasesClicked = () => {
        setOpen(false);
        navigate(Paths.useCases);
    };

    const resourcesClicked = () => {
        setOpen(false);
        navigate(Paths.resources);
    };

    const scheduleDemoClicked = () => {
        setOpen(false);
        setIsScheduleDemoDialogOpen(true);
    };

    const logoutClicked = () => {
        setOpen(false);
        authContext.signOut();
        navigate(Paths.landing);
    };

    const showNonDismissableLoginDialog = () => {
        setDisableBackdropDismiss(true);
        setLoginOpen(true);
    };

    const handleLogoClick = () => {
        setOpen(false);
        navigate(isWhitelistedUser(authContext) ? Paths.dashboard : Paths.landing + "?show_home=true");
    };

    const showExtraLinks = (): boolean => {
        return !authContext.user;
    };

    return (
        <NavLinksContainer>
            {isOpen && <div className="nav-background"></div>}
            {isOpen && (
                <LinksWrapper className="inset-padding-top">
                    <LinkItem className="d-flex flex-column pt-3 pb-3 ps-3">
                        <div className="d-flex align-items-center">
                            <span style={{ display: "inline", width: "98px" }} onClick={handleLogoClick}>
                                <Logo theme="dark" />
                            </span>
                            {props.hasCoBrandLogoUrl && (
                                <>
                                    <div className="vertical-divider ms-2 me-2" style={{ height: "36px" }}></div>
                                    <Link to={props.coBrandNav || authContext.user?.coBrandNavUrl || "/"} className={props.theme === "dark" ? "overflow-hidden" : ""}>
                                        <CoBrandLogo className={props.theme === "dark" ? "" : "svg-shadow"} logoUrl={props.coBrandLogo || authContext.user?.coBrandLogoUrl || ""} />
                                    </Link>
                                </>
                            )}
                        </div>
                    </LinkItem>
                    <Divider />
                    <MenuList>
                        {authContext.user && (
                            <MenuItem>
                                <ListItemIcon>
                                    <IconButton className="p-0 user-avatar">
                                        <Avatar
                                            sx={{ width: "1.6rem", height: "1.6rem" }}
                                            alt={authContext.user?.name || authContext.user?.email}
                                            src={authContext.user.profileImageUrl || "/static/images/avatar/3.jpg"}
                                        />
                                    </IconButton>
                                </ListItemIcon>
                                <div>
                                    <div>{authContext.user?.name || authContext.user?.email.split("@")[0]}</div>
                                    <div className="text-decoration-none">{" (" + authContext.user?.email + ")"}</div>
                                </div>
                            </MenuItem>
                        )}
                        {authContext.user && <Divider className="ms-2 me-2" />}
                        {isWhitelistedUser(authContext) && !props.hideCreateEmovidButtonInMenu && !isValidateMFAPath && (
                            <CreateEmovidButton onSuccess={() => setOpen(false)} isMenuButton={true} buttonText="Create New Emovid" location="nav_menu"></CreateEmovidButton>
                        )}
                        {isWhitelistedUser(authContext) && !isValidateMFAPath && (
                            <MenuItem onClick={dashboardClicked}>
                                <ListItemIcon>
                                    <DashboardIcon fontSize="small" />
                                </ListItemIcon>
                                My Inbox
                            </MenuItem>
                        )}
                        {isWhitelistedUser(authContext) && !isValidateMFAPath && (
                            <MenuItem onClick={createdVideosClicked}>
                                <ListItemIcon>
                                    <CreatedVideosIcon style={{ width: "1.2rem", height: "1.2rem" }} />
                                </ListItemIcon>
                                Created Emovids
                            </MenuItem>
                        )}
                        {showExtraLinks() && !isValidateMFAPath && (
                            <MenuItem onClick={howItWorksClicked}>
                                <ListItemIcon>
                                    <MenuBookRoundedIcon />
                                </ListItemIcon>
                                How it Works
                            </MenuItem>
                        )}
                        {showExtraLinks() && !isValidateMFAPath && (
                            <MenuItem onClick={useCasesClicked}>
                                <ListItemIcon>
                                    <ListAltRoundedIcon width="24px" />
                                </ListItemIcon>
                                Use Cases
                            </MenuItem>
                        )}
                        {(showExtraLinks() || isWhitelistedUser(authContext)) && !isValidateMFAPath && <Divider className="ms-2 me-2" />}
                        {isWhitelistedUser(authContext) && !isValidateMFAPath && (
                            <MenuItem onClick={resourcesClicked}>
                                <ListItemIcon>
                                    <InfoRoundedIcon width="24px" />
                                </ListItemIcon>
                                Resources
                            </MenuItem>
                        )}
                        {!isNativePlatform() && !isValidateMFAPath && (
                            <MenuItem onClick={homeClicked}>
                                <ListItemIcon>
                                    <HomeRoundedIcon width="24px" />
                                </ListItemIcon>
                                Home
                            </MenuItem>
                        )}
                        {!isValidateMFAPath && <Divider className="ms-2 me-2" />}
                        {!isValidateMFAPath && (
                            <MenuItem onClick={getSupportClicked}>
                                <ListItemIcon>
                                    <HelpRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                Get Support
                            </MenuItem>
                        )}
                        {authContext.user?.userId && !isValidateMFAPath && (
                            <MenuItem onClick={scheduleDemoClicked}>
                                <ListItemIcon>
                                    <EventAvailableRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                Schedule a Demo
                            </MenuItem>
                        )}
                        {authContext.user?.userId && !isValidateMFAPath && (
                            <MenuItem onClick={sendFeedbackClicked}>
                                <ListItemIcon>
                                    <ThumbUpAltRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                Send Feedback
                            </MenuItem>
                        )}
                        {!authContext.user?.userId && !isValidateMFAPath && (
                            <MenuItem onClick={pricingClicked}>
                                <ListItemIcon>
                                    <LocalOfferRoundedIcon />
                                </ListItemIcon>
                                Plans & Pricing
                            </MenuItem>
                        )}
                        {!isValidateMFAPath && (
                            <MenuItem onClick={salesClicked}>
                                <ListItemIcon>
                                    <ContactSalesIcon />
                                </ListItemIcon>
                                Contact Us
                            </MenuItem>
                        )}
                        {!authContext.user?.userId && !isValidateMFAPath && (
                            <MenuItem onClick={aboutUsClicked}>
                                <ListItemIcon>
                                    <Diversity3RoundedIcon />
                                </ListItemIcon>
                                About Us
                            </MenuItem>
                        )}
                        {!authContext.user?.userId && !isValidateMFAPath && (
                            <MenuItem onClick={pressClicked}>
                                <ListItemIcon>
                                    <NewspaperRoundedIcon />
                                </ListItemIcon>
                                Press
                            </MenuItem>
                        )}
                        {!isValidateMFAPath && <Divider className="ms-2 me-2" />}
                        {isWhitelistedUser(authContext) && !isValidateMFAPath && (
                            <MenuItem onClick={myAccountClicked}>
                                <ListItemIcon>
                                    <ManageAccountsRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                My Account
                            </MenuItem>
                        )}
                        {!authContext.user && (
                            <MenuItem onClick={signInClicked}>
                                <ListItemIcon>
                                    <LoginIcon fontSize="small" />
                                </ListItemIcon>
                                Sign In
                            </MenuItem>
                        )}
                        {authContext.user && (
                            <MenuItem onClick={logoutClicked}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        )}
                        <br />
                        <br />
                        <br />
                    </MenuList>
                </LinksWrapper>
            )}
            <div className="position-absolute" style={{ top: props.showTrialExpiredWarning ? (isOpen ? "-45px" : "6px") : "6px", right: "10px", zIndex: 1300, height: "40px" }}>
                <span style={{ opacity: !isOpen && isWhitelistedUser(authContext) && !isValidateMFAPath ? 1 : 0 }}>
                    <NotificationsIconButton />
                </span>
                &nbsp;&nbsp;
                <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
            </div>
            <Popup isOpen={isLoginOpen} disableBackdropDismiss={disableBackdropDismiss} onClose={() => setLoginOpen(false)} className="login-popup">
                <Login
                    title={disableBackdropDismiss ? "Please login to continue" : ""}
                    disableCloseBtn={disableBackdropDismiss}
                    handleClose={loginComplete}
                    disableSignup={props.disableSignup !== undefined ? props.disableSignup : true}
                />
            </Popup>
            <Dialog
                open={isScheduleDemoDialogOpen}
                className="more-popup"
                maxWidth={false}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setIsScheduleDemoDialogOpen(false)}
            >
                <IconButton
                    className={"close-icon " + (isDesktop || isTablet ? "text-white" : "text-dark")}
                    aria-label="Close"
                    onClick={() => setIsScheduleDemoDialogOpen(false)}
                    style={{ right: "20px" }}
                >
                    <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <InlineWidget url="https://calendly.com/d/cpqp-dr8-fd9/guided-product-walk-through-with-co-founder" />
            </Dialog>
            <button
                id="session-expiry-hidden-login-btn"
                className="position-absolute"
                style={{ top: "-50000px", left: "-50000px" }}
                onClick={showNonDismissableLoginDialog}
            ></button>
        </NavLinksContainer>
    );
};

export default MobileNavLinks;
