import { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { IPostMetadataAttributes } from "@emovid/payloads-library";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ResponsesTabIcon from "../../Icons/ResponsesTabIcon";
import RecipientsTabIcon from "../../Icons/RecipientsTabIcon";
import { Paths, replaceEventIdInPath } from "../../Common/AppRoutes";

export const translucentBackgroundColor = "rgba(0,0,0,0.5)";

export const StyledBox = styled(Box)(() => ({
    backgroundColor: translucentBackgroundColor
}));

export const Puller = styled(Box)(() => ({
    width: 30,
    height: 6,
    backgroundColor: "#fff",
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)"
}));

export interface TabItem {
    title: string;
    icon: ReactElement;
}

export enum ResponseRecipientDesktopMenu {
    RESPONSES = 0,
    RECIPIENTS = 1,
    PRIVACY_SETTINGS = 2
}

export enum ResponseRecipientMobileMenu {
    INFO = 0,
    RESPONSES = 1,
    RECIPIENTS = 2,
    PRIVACY_SETTINGS = 3
}

export enum PrivacySettingsState {
    SIGNED_IN_USERS_CAN_VIEW_POST = "SIGNED_IN_USERS_CAN_VIEW_POST",
    RECIPIENTS_CAN_VIEW_POST_LOGGED_IN = "RECIPIENTS_CAN_VIEW_POST_LOGGED_IN",
    RECIPIENTS_CAN_VIEW_POST_NON_LOGGED_IN = "RECIPIENTS_CAN_VIEW_POST_NON_LOGGED_IN"
}

export const EmailRecipientWhitelistUserStorageKey = `isUserWhitelisted`;
export const EmailRecipientTrialExpiredUserStorageKey = `isTrialExpiredMember`;
export const EmailRecipientUserDetailsStorageKey = "emailRecipientUserDetails";
export const WAITLIST_MESSAGE = "Sorry! You do not have access to full Emovid functionality yet.";
export const getTotalViews = (
    loggedinUserId: string,
    eventCreatorId: string,
    postViews: IPostMetadataAttributes[],
    unknownViews: number,
    privateReplies: boolean,
    numberOnly = false
) => {
    let views = unknownViews || 0;
    // if (privateReplies && loggedinUserId !== eventCreatorId) views = 0; // this will hide unknown views
    (postViews || []).forEach(postView => {
        if (privateReplies && loggedinUserId !== eventCreatorId) {
            views += loggedinUserId !== postView.user_id ? postView?.views || 0 : 0;
        } else if (loggedinUserId !== postView.user_id) views += postView?.views || 0;
    });
    return numberOnly ? `${views}` : `${views} view${views !== 1 ? "s" : ""}`;
};
export const responseRecipientTabs: TabItem[] = [
    { title: "Responses", icon: <ResponsesTabIcon /> },
    { title: "Recipients", icon: <RecipientsTabIcon /> }
];
export const mobileResponseRecipientTabs: TabItem[] = [
    { title: "Details", icon: <InfoRoundedIcon /> },
    { title: "Responses", icon: <ResponsesTabIcon /> },
    { title: "Recipients", icon: <RecipientsTabIcon /> }
];

export const getPathForEmovidDashboard = (eventId: string): string => {
    let path = replaceEventIdInPath(Paths.viewEmovid, eventId);
    return path + (window.location.search ? window.location.search : "?") + "&dashboard_mode=true";
};
