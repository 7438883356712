import React, { FC } from "react";
import TextTransition, { presets } from "react-text-transition";

interface AnimatedTextBlurbsProps {
    items: string[];
    containerClasses?: string;
    textElementClasses?: string;
    duration?: number;
}

const AnimatedTextBlurbs: FC<AnimatedTextBlurbsProps> = ({ items, containerClasses, textElementClasses, duration }: AnimatedTextBlurbsProps) => {
    const [index, setIndex] = React.useState<number>(0);
    const [intervalId, setIntervalId] = React.useState<any>(0);

    React.useEffect(() => {
        if (items.length === 0) return;
        if (intervalId) clearTimeout(intervalId);
        let intervalIdTemp = setTimeout(() => setIndex(index => index + 1), duration || 3000);
        setIntervalId(intervalIdTemp);
        return () => clearTimeout(intervalId);
    }, [index]);

    return (
        <TextTransition className={containerClasses || ""} springConfig={presets.wobbly}>
            <div className={textElementClasses || ""}>{items[index % items.length]}</div>
        </TextTransition>
    );
};

export default AnimatedTextBlurbs;
