import { FC, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import NavBar from "../Nav/NavBar";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";
import Error from "../../Common/Error";
import Progress from "../../Common/Progress";
import { Paths } from "../../Common/AppRoutes";
import { AuthContext } from "../../Contexts/AuthContext";

interface IUpdatePasswordData {
    password: string;
    confirmPassword: string;
}

const UpdatePassword: FC = () => {
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [authHeader, setAuthHeader] = useState<string>("");
    const location = useLocation();
    const navigate = useNavigate();
    const { register, formState, clearErrors, setError, handleSubmit, watch } = useForm<IUpdatePasswordData>({
        defaultValues: {
            password: "",
            confirmPassword: ""
        },
        mode: "onChange"
    });

    useQuery({
        queryKey: "validateToken",
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        queryFn: () => {
            authContext.setUserData(undefined);
            localStorage.removeItem("userData");
            const token = location.search.split("reset-token=")[1];
            return HttpService.getWithCustomHeaders("/v1/reset-password/validate", { Authorization: token });
        },
        onError: (error: any) => {
            ToastService.userError("Invalid link.");
        },
        onSuccess: (data: any) => {
            setAuthHeader(data.headers.authorization);
        }
    });

    useEffect(() => {
        const subscription = watch(value => {
            if (value.password && value.confirmPassword && value.password !== value.confirmPassword) {
                setError("root.passwordNotMatching", { type: "custom" });
            } else if (formState.errors.root?.passwordNotMatching) clearErrors("root.passwordNotMatching");
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const resetPassword = async (data: any) => {
        setLoading(true);
        try {
            await HttpService.putFormData("/v1/reset-password/update", { password: data.password }, true, { Authorization: authHeader });
            ToastService.success("Password updated successfully. Please login.");
            navigate(Paths.landing + "?show_login=true");
            setLoading(false);
        } catch (error: any) {
            ToastService.alertableError("Error while resetting password", error);
            setLoading(false);
        }
    };

    if (!authHeader) {
        return <Progress />;
    }

    return (
        <>
            <NavBar theme="dark" />
            <div className="container container-max-width p-4">
                <h2 className="mb-4">Update Password</h2>
                {formState.errors.root && formState.errors.root.passwordNotMatching.type === "custom" && (
                    <div className="mb-3">
                        <Error>Entered passwords are different</Error>
                    </div>
                )}
                <form onSubmit={handleSubmit(resetPassword)}>
                    <div className="form-group mb-4">
                        <input
                            type="password"
                            className={formState.errors.password && formState.touchedFields.password ? "form-control error" : "form-control"}
                            {...register("password", { required: true })}
                            placeholder="Enter password"
                        />
                        {formState.errors.password && formState.errors.password?.type === "required" && <Error>Please enter password</Error>}
                    </div>
                    <div className="form-group mb-4">
                        <input
                            type="password"
                            className={formState.errors.confirmPassword && formState.touchedFields.confirmPassword ? "form-control error" : "form-control"}
                            {...register("confirmPassword", { required: true })}
                            placeholder="Confirm password"
                        />
                        {formState.errors.confirmPassword && formState.errors.confirmPassword?.type === "required" && <Error>Please enter password again</Error>}
                    </div>
                    <div className="d-flex">
                        <button
                            type="submit"
                            className="btn btn-primary mb-3 px-3 text-white animated-text mr-3"
                            disabled={loading || (formState.errors.root && formState.errors.root.passwordNotMatching.type === "custom")}
                        >
                            Submit
                        </button>
                        {loading && <CircularProgress />}
                    </div>
                </form>
            </div>
        </>
    );
};

export default UpdatePassword;
