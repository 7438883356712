import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { H } from "highlight.run";
import axios from "axios";
import { App as CapacitorApp } from "@capacitor/app";
import { SocialLogin } from "@capgo/capacitor-social-login";
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { PushNotificationStatus } from "@emovid/payloads-library";
import { getCsrfToken, getDeviceInfo, isNativePlatform, pushTagEvent, titleForPath } from "./Common/Helper";
import { APP_VERSION } from "./Common/Constants";
import { AuthContext } from "./Contexts/AuthContext";
import NotificationService from "./Services/NotificationService";
import Footer from "./Common/Footer";
import "./App.scss";

const darkTheme = createTheme({
    palette: { mode: "dark" },
    typography: { fontFamily: ["Chivo"].join(",") }
});

const queryClient = new QueryClient();

function App() {
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [appVersion, setAppVersion] = useState<string>(APP_VERSION || "");

    useEffect(() => {
        if (isNativePlatform()) {
            getDeviceInfo()
                .then(deviceInfo => {
                    if (!deviceInfo.model.includes("iPad")) {
                        PushNotifications.requestPermissions().then(result => {
                            if (result.receive === "granted") {
                                console.log("User granted push notifications");
                                PushNotifications.register();
                            } else {
                                console.error("User did not grant push notifications");
                            }
                        });

                        // On success, we should be able to receive notifications
                        PushNotifications.addListener("registration", async (token: Token) => {
                            console.log("Push registration success");
                            localStorage.setItem("pushToken", token.value);
                            console.log("token", token);
                            const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || "") : null;
                            console.log("userData", userData);
                            if (token.value && userData) {
                                await NotificationService.saveToken(token.value, userData.userId);
                            }
                        });

                        // Some issue with our setup and push will not work
                        PushNotifications.addListener("registrationError", (error: any) => {
                            console.error("Error on registration: " + JSON.stringify(error));
                        });

                        // Show us the notification payload if the app is open on our device
                        PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
                            console.log("Push received: " + JSON.stringify(notification));
                        });

                        // Method called when tapping on a notification
                        PushNotifications.addListener("pushNotificationActionPerformed", async (data: ActionPerformed) => {
                            console.log("Push action performed: " + JSON.stringify(data));
                            const { notification } = data;
                            if (notification?.data?.notification_history_id) {
                                await NotificationService.updateStatus(notification?.data?.notification_history_id, PushNotificationStatus.OPENED);
                            }
                            if (notification?.data?.path) {
                                navigate(notification?.data?.path);
                            }
                        });

                        SocialLogin.initialize({
                            google: {
                                iOSClientId: "133451818534-mdpg0vsh5a8qlanel7d2ptm2kljndg6v.apps.googleusercontent.com",
                                webClientId: "133451818534-d79ffevu20j72av4irhpga8hr06mndjv.apps.googleusercontent.com"
                            }
                        });
                    }
                })
                .catch(error => {
                    console.error("Error getting device info", error);
                });
        }
    }, []);

    useEffect(() => {
        axios.defaults.headers.common["x-csrf-token"] = getCsrfToken();
        H.getSessionDetails().then(({ url, urlWithTimestamp }) => localStorage.setItem("last_highlight_session", url));
        if (location.search.includes("popup_mode=true")) {
            sessionStorage.setItem("popup_mode", "true");
        }
        if (location.search.includes("creator_email=")) sessionStorage.setItem("creator_email", new URLSearchParams(location.search).get("creator_email") || "");
    }, []);

    useEffect(() => {
        if (authContext.user) {
            pushTagEvent("e_user_data", {
                user_email: authContext.user.email,
                user_id: authContext.user.userId,
                logged_in: "yes"
            });
        }
        document.title = titleForPath(location.pathname);
    }, [authContext.user, location]);

    useEffect(() => {
        CapacitorApp.getInfo()
            .then(appInfo => {
                setAppVersion(`${appInfo.version} (${appInfo.build})`);
            })
            .catch(error => {
                console.log(`REACT_APP_VERSION: ${APP_VERSION}`);
                // do nothing; this is expected on web
            });
    }, []);

    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
        if (!canGoBack) {
            CapacitorApp.exitApp();
        } else {
            window.history.back();
        }
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <main className="inset-padding-top">
                    <Outlet />
                </main>
                <ToastContainer position="top-center" className="inset-padding-top" />
                <Footer version={appVersion} />
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
