import { Backdrop, CircularProgress } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { Paths, replaceEventIdInPath } from "../../Common/AppRoutes";
import HttpService from "../../Services/HttpService";
import { AuthContext } from "../../Contexts/AuthContext";
import { IJWTTokenPayload, MemberType } from "@emovid/payloads-library";
import jwt from "jwt-decode";
import axios from "axios";
import { logError } from "../../Common/Helper";
import { API_KEY, BACKEND_URL } from "../../Common/Constants";

const progress = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    height: "calc(100vh - 60px)"
};

const Integrate = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const ref = useRef(false);

    const autoLoginAndRedirect = async () => {
        try {
            if (!ref.current) {
                if (authContext.user) {
                    authContext.signOut();
                }
                ref.current = true;
                console.log("document.referrer", document.referrer);
                if (window.location.search.includes("data")) {
                    const queryParams = new URLSearchParams(window.location.search);
                    const data: any = queryParams.get("data") ? JSON.parse(atob(queryParams.get("data") as string)) : null;
                    if (data) {
                        // auto log in user
                        const autoLoginResponse = await HttpService.postFormData(
                            `/v1/user/auto/login`,
                            {
                                email: data?.email,
                                name: data?.name,
                                source: data?.source
                            },
                            false,
                            true,
                            {
                                origin: document.referrer
                            }
                        );
                        await authContext.retrieveUserDataFromTokenAndRedirect(autoLoginResponse.token, true);
                        const decodedToken = jwt(autoLoginResponse.token as string) as IJWTTokenPayload;
                        // create emovid
                        const createEmovidResponse = await HttpService.postFormData(
                            `/v1/event/create`,
                            {
                                creator_id: decodedToken.userId,
                                permissions: {
                                    is_public: false,
                                    is_active: true,
                                    is_creator_a_contributor: true
                                },
                                title: ""
                            },
                            false,
                            true
                        );
                        if (data?.recipients && createEmovidResponse?.event?.CreatorPosts[0]?.id) {
                            // add members to emovid
                            const users = data?.recipients.map((recipient: any) => {
                                return {
                                    ...recipient,
                                    member_type: MemberType.TO
                                };
                            });
                            let request = {
                                method: "post",
                                url: `${BACKEND_URL}/v1/email-plugin/members/add`,
                                headers: { authorization: `Basic ${API_KEY}` },
                                data: {
                                    users,
                                    added_in_post_id: createEmovidResponse.event.CreatorPosts[0].id,
                                    event_id: createEmovidResponse.event.id
                                }
                            };
                            await axios(request);
                        }
                        return navigate(replaceEventIdInPath(Paths.recordEmovid, createEmovidResponse.event.id));
                    }
                }
                return navigate(Paths.landing, { replace: true });
            }
        } catch (error: any) {
            console.log(error);
            logError("AutoLoginError", new Error(error.message), { error });
            navigate(Paths.landing, { replace: true });
        }
    };

    useEffect(() => {
        autoLoginAndRedirect();
    }, []);

    return (
        <div style={progress}>
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.modal + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Integrate;
