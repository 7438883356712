import { AccountType, IUserAttributes, IUserPreferences, Role } from "@emovid/payloads-library";
import { IUserInfo } from "../Interfaces/IUserInfo";

export function SetLoginUserInfoMap(
    email: string,
    userId: string,
    name: string,
    accountType: AccountType,
    token: string,
    role: Role,
    phoneNumber: string,
    profileImageUrl: string,
    mfaEnabled: boolean,
    isInternal: boolean,
    coBrandLogoUrl: string,
    coBrandNavUrl: string,
    preferences: IUserPreferences
): IUserInfo {
    return {
        email,
        name,
        userId,
        account_type: accountType,
        token,
        role,
        phoneNumber,
        profileImageUrl,
        mfaEnabled,
        isInternal,
        mfaVerified: false,
        coBrandLogoUrl,
        coBrandNavUrl,
        preferences
    };
}

export const displayNameForUser = (user: IUserAttributes) => {
    if (!user) return "";
    return user.name || user.email.split("@")[0];
};
