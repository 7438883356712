import { useContext, Component, createRef } from "react";
import { useNavigate } from "react-router";
import { IJWTTokenPayload } from "@emovid/payloads-library";
import jwt from "jwt-decode";
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { IUserInfo } from "../Interfaces/IUserInfo";
import { AuthContext } from "./AuthContext";
import { AccountType } from "../Enums/AccountType";
import { ACCOUNT_NOT_FOUND_MESSAGE, API_KEY, BACKEND_URL, MICROSOFT_ANDROID_HASH } from "../Common/Constants";
import { MICROSOFT_AZURE_AD_CLIENT_ID, MICROSOFT_AZURE_AD_TENANT_ID, MICROSOFT_CLIENT_ID } from "../Common/Constants";
import { LogValues, getEncryptionKeys, getInboxCountInfo, getUserInfo, identifyUserSession, isIOSPlatform, isNativePlatform, pushTagEvent } from "../Common/Helper";
import { SetLoginUserInfoMap } from "../Util/UserInfoMap";
import HttpService from "../Services/HttpService";
import Progress from "../Common/Progress";
import { DefaultLoggedinUserPath, Paths } from "../Common/AppRoutes";
import { isWhitelistedEmail, isWhitelistedUser } from "../Common/GuardedRoute";
import { CookiesDisabledPopup } from "../Common/CookiesDisabledPopup";
import NotificationService from "../Services/NotificationService";
import { GoogleLoginResponseOnline, SocialLogin } from "@capgo/capacitor-social-login";
import { IMobileAuthLoginRequest } from "../Interfaces/IMobileAuthLoginRequest";
import ToastService from "../Common/ToastService";
import { Capacitor } from "@capacitor/core";
import { MsAuthPlugin } from "@recognizebv/capacitor-plugin-msauth";

export interface AuthData {
    keys: any;
    signIn: (accountType: AccountType, loginHint?: string) => void;
    signOut: () => void;
    setUserData: (user: any) => void;
    setWhitelisted: () => void;
    checkEmailWhitelisted: (email: string) => void;
    retrieveUserDataFromTokenAndRedirect: (token: any, isAutoLogin?: boolean) => void;
    user: IUserInfo | undefined;
    isWhitelisted: boolean;
    isTrialUser: boolean;
    trialDaysLeft: number;
    hasTrialEnded: boolean;
}

interface AuthProps {
    navigate: any;
    children: React.ReactNode;
}

interface AuthState {
    user: IUserInfo | undefined;
    isWhitelisted: boolean;
    isTrialUser: boolean;
    trialDaysLeft: number;
    hasTrialEnded: boolean;
    keys: any;
    loading: boolean;
    securityError: boolean;
}

export class AuthProvider extends Component<AuthProps, AuthState> {
    isCsrfTokenSet = createRef<boolean | null>();
    state: AuthState = {
        user: undefined,
        keys: undefined,
        isWhitelisted: false,
        isTrialUser: false,
        hasTrialEnded: false,
        trialDaysLeft: 0,
        loading: true,
        securityError: false
    };

    async componentDidMount() {
        try {
            if (!this.state.loading) return;
            await this.setCsrfToken();
            await this.getCookies();
            await this.setMFAInfo();
            await this.setToken();
            if (localStorage.getItem("userData")) {
                await this.setUserData();
            } else {
                this.setUserData();
            }
            this.setState({ loading: false });
        } catch (error: any) {
            if (error?.name === "SecurityError") {
                this.setState({ securityError: true, loading: false });
            }
        }
    }

    setCsrfToken = async () => {
        try {
            console.log("csrfTokenSet", this.isCsrfTokenSet.current);
            if (this.isCsrfTokenSet.current) return;
            // @ts-ignore
            this.isCsrfTokenSet.current = true;
            await axios.get(`${BACKEND_URL}/v1/security/csrf`, {
                headers: {
                    authorization: `Basic ${API_KEY}`
                },
                withCredentials: true
            });
        } catch (error: any) {
            console.error("Error while getting csrf token", error);
        }
    };

    signIn = async (accountType: AccountType, loginHint?: string) => {
        let currentPath = window.location.pathname + window.location.search;
        sessionStorage.setItem("redirectPath", currentPath);
        if (isNativePlatform()) {
            try {
                let mobileAuthLoginPayload: IMobileAuthLoginRequest | null = null;
                if (accountType === AccountType.GOOGLE) {
                    mobileAuthLoginPayload = await this.googleNativeSignIn();
                } else if (accountType === AccountType.MICROSOFT) {
                    mobileAuthLoginPayload = await this.microsoftSignIn();
                } else if (accountType === AccountType.MICROSOFT_AZURE_AD) {
                    mobileAuthLoginPayload = await this.microsoftSSONativeSignIn();
                }
                if (mobileAuthLoginPayload) {
                    const validateResponse = await HttpService.postFormData("/v1/user/validate", { email: mobileAuthLoginPayload.email }, false, true);
                    if (!validateResponse.user_exists || (validateResponse.user_exists && !validateResponse.is_active)) {
                        ToastService.info(ACCOUNT_NOT_FOUND_MESSAGE);
                        localStorage.setItem(
                            "userData",
                            JSON.stringify({
                                account_type: accountType
                            })
                        );
                        this.signOut();
                    } else {
                        const authResponse = await HttpService.postFormData("/v1/auth/mobile", mobileAuthLoginPayload, false, true);
                        if (authResponse) {
                            await this.retrieveUserDataFromTokenAndRedirect(authResponse.token);
                        }
                    }
                }
            } catch (error: any) {
                console.error(`Error while signing in with ${accountType}`, error);
            }
        } else {
            window.location.href = `${BACKEND_URL}/v1/auth/${accountType === AccountType.MICROSOFT_AZURE_AD ? "sso/microsoft/azure/ad" : accountType}?successRef=${
                window.location.pathname
            }&errorRef=/&loginHint=${loginHint || ""}`;
        }
    };

    googleNativeSignIn = async (): Promise<IMobileAuthLoginRequest | null> => {
        try {
            const response = await SocialLogin.login(
                isIOSPlatform()
                    ? {
                          provider: "google",
                          options: {
                              scopes: ["profile", "email"],
                              forceRefreshToken: true
                          }
                      }
                    : {
                          provider: "google",
                          options: {}
                      }
            );
            if (response?.result) {
                const result = response.result as GoogleLoginResponseOnline;
                return {
                    email: result.profile.email || "",
                    name: result.profile.name || result.profile.givenName || (result.profile.email ? result.profile.email.split("@")[0] : ""),
                    type: AccountType.GOOGLE,
                    id: result.profile.id || "",
                    token: result.accessToken?.token || ""
                };
            }
            return null;
        } catch (error: any) {
            console.log("Error while signing in with Google in", Capacitor.getPlatform(), error);
            return null;
        }
    };

    microsoftSignIn = async (): Promise<IMobileAuthLoginRequest | null> => {
        if (!MICROSOFT_CLIENT_ID) {
            console.error("Microsoft client id not set");
            ToastService.alertableError("Microsoft client id not set");
            return null;
        }
        try {
            const result = await MsAuthPlugin.login({
                clientId: MICROSOFT_CLIENT_ID,
                tenant: "consumers",
                scopes: ["user.read"],
                keyHash: MICROSOFT_ANDROID_HASH,
                authorityType: "AAD"
            });
            if (result.idToken) {
                const decodedToken: any = jwt(result.idToken as string);
                return {
                    email: decodedToken.email || decodedToken.unique_name || decodedToken.preferred_username || "",
                    name: decodedToken.name || decodedToken.givenName || decodedToken.email.split("@")[0],
                    id: decodedToken.oid || "",
                    type: AccountType.MICROSOFT,
                    token: result?.accessToken?.substring(0, 255) || ""
                };
            }
            return null;
        } catch (error: any) {
            console.log("Error while signing in with Microsoft in", Capacitor.getPlatform(), error);
            return null;
        }
    };

    microsoftSSONativeSignIn = async (): Promise<IMobileAuthLoginRequest | null> => {
        if (!MICROSOFT_AZURE_AD_CLIENT_ID || !MICROSOFT_AZURE_AD_TENANT_ID) {
            console.error("Microsoft Azure AD config not set");
            ToastService.alertableError("Microsoft Azure AD config not set");
            return null;
        }
        try {
            const result = await MsAuthPlugin.login({
                clientId: MICROSOFT_AZURE_AD_CLIENT_ID,
                tenant: MICROSOFT_AZURE_AD_TENANT_ID,
                scopes: ["user.read"],
                keyHash: MICROSOFT_ANDROID_HASH,
                authorityType: "AAD"
            });
            if (result.accessToken) {
                const decodedToken: any = jwt(result.accessToken as string);
                console.log("decodedToken", decodedToken);
                return {
                    email: decodedToken.email || decodedToken.unique_name || decodedToken.preferred_username || "",
                    name: decodedToken.name || decodedToken.givenName || decodedToken.email.split("@")[0],
                    id: decodedToken.oid || "",
                    type: AccountType.MICROSOFT_AZURE_AD,
                    token: result?.idToken?.substring(0, 255) || ""
                };
            }
            return null;
        } catch (error: any) {
            console.log("Error while signing in with Microsoft in", Capacitor.getPlatform(), error);
            return null;
        }
    };

    signOut = () => {
        if (isNativePlatform()) {
            const provider = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || "").account_type : "";
            if (provider === AccountType.GOOGLE) {
                SocialLogin.logout({
                    provider
                });
            } else if (provider === AccountType.MICROSOFT) {
                MsAuthPlugin.logout({
                    clientId: MICROSOFT_CLIENT_ID!,
                    keyHash: MICROSOFT_ANDROID_HASH
                });
            } else if (provider === AccountType.MICROSOFT_AZURE_AD) {
                MsAuthPlugin.logout({
                    clientId: MICROSOFT_AZURE_AD_CLIENT_ID!,
                    tenant: MICROSOFT_AZURE_AD_TENANT_ID,
                    keyHash: MICROSOFT_ANDROID_HASH
                });
            }
        }
        this.setState({ user: undefined, isTrialUser: false, hasTrialEnded: false, isWhitelisted: false, trialDaysLeft: 0 });
        NotificationService.removeToken(localStorage.getItem("pushToken") || "");
        localStorage.clear();
        sessionStorage.clear();
        pushTagEvent("e_logout");
        amplitude.reset();
        LogValues("AuthProvider - sign out block");
    };

    setUserData = async () => {
        if (localStorage.getItem("userData")) {
            const token = JSON.parse(localStorage.getItem("userData") || "").token;
            let decodedToken: any;
            if (token) decodedToken = jwt(token as string);
            const time = new Date().getTime();
            if (!decodedToken) {
                this.setState({ user: undefined });
                localStorage.removeItem("userData");
                LogValues("AuthProvider - decoded token null or undefined", { pathname: window.location.pathname });
            } else if (decodedToken && decodedToken.exp * 1000 < time) {
                this.setState({ user: undefined });
                localStorage.removeItem("userData");
                LogValues("AuthProvider - decoded token expired", { pathname: window.location.pathname });
            } else {
                LogValues("AuthProvider - user data else block");
                let userDataParsed = JSON.parse(localStorage.getItem("userData") || "{}");
                this.setState({ user: userDataParsed });
                await this.checkEmailWhitelisted(userDataParsed.email);
                await NotificationService.triggerRegistration();
                identifyUserSession({ userId: userDataParsed.userId, email: userDataParsed.email, name: userDataParsed.name, isInternal: userDataParsed.isInternal });
            }
        }
    };

    setToken = async () => {
        if (window.location.search.includes("token")) {
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get("token") as string;
            await this.retrieveUserDataFromTokenAndRedirect(token);
        }
    };

    checkEmailWhitelisted = async (email: string) => {
        try {
            const response = await HttpService.getWithAPIKey(`/v1/whitelisted/status/${email}`);
            this.setState({
                isWhitelisted: response.is_whitelisted,
                isTrialUser: response.is_trial_user,
                hasTrialEnded: response.has_trial_ended,
                trialDaysLeft: response.trial_days_left
            });
            localStorage.setItem("last_whitelisted_status", `${response.is_whitelisted || isWhitelistedEmail({ user: this.state.user, isWhitelisted: false }, email)}`);
            return response.is_whitelisted;
        } catch (error) {}
    };

    setWhitelisted = () => {
        this.setState({ isWhitelisted: true, isTrialUser: false, hasTrialEnded: false, trialDaysLeft: 0 });
        this.setUserData();
    };

    getKeys = async () => {
        const path = "/v1/keys";
        try {
            const keys = await getEncryptionKeys();
            this.setState({ keys });
        } catch (error: any) {
            return HttpService.handleResponse(path, error.response, true);
        }
    };

    getCookies = async () => {
        try {
            await axios.get(`${BACKEND_URL}/v1/generate/cookies/hls`, {
                headers: { authorization: `Basic ${API_KEY}` },
                withCredentials: true
            });
        } catch (error: any) {
            console.error("Error while getting cookies", error);
        }
    };

    setMFAInfo = async () => {
        try {
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "{}");
                if (userData.userId) {
                    const userInfo = await getUserInfo(userData.userId);
                    if (userInfo) {
                        userData.mfaEnabled = userInfo.mfa_enabled;
                        localStorage.setItem("userData", JSON.stringify(userData));
                        this.setState({ user: userData });
                    }
                }
            }
        } catch (error: any) {
            console.error("Error while setting mfa info", error);
        }
    };

    setLoggedInUser = (user: any) => {
        this.setState({ user });
    };

    retrieveUserDataFromTokenAndRedirect = async (token: any, isAutoLogin: boolean = false) => {
        if (token) {
            const decodedToken = jwt(token as string) as IJWTTokenPayload;
            const userData: IUserInfo = SetLoginUserInfoMap(
                decodedToken.email,
                decodedToken.userId,
                decodedToken.name,
                decodedToken.accountType,
                token,
                decodedToken.role,
                decodedToken.phoneNumber,
                decodedToken.profileImageUrl,
                decodedToken.mfaEnabled,
                decodedToken.isInternal,
                decodedToken.coBrandLogoUrl || "",
                decodedToken.coBrandNavUrl || "",
                decodedToken.preferences
            );
            this.setState({ user: userData });
            localStorage.setItem("userData", JSON.stringify(userData));
            LogValues("AuthProvider - token block");
            if (isAutoLogin) {
                pushTagEvent("e_logged_in", { email: userData.email, account_type: userData.account_type });
            } else {
                const isWhitelisted = await this.checkEmailWhitelisted(userData.email); // check first to prevent race condition
                const isUserWhiteListed = isWhitelistedUser({
                    user: userData,
                    isWhitelisted
                });
                let redirectPath = sessionStorage.getItem("redirectPath") || "";
                let defaultPath = DefaultLoggedinUserPath;
                try {
                    if (isUserWhiteListed) {
                        // to be done only if whitelisted user
                        let inboxCountResp = await getInboxCountInfo();
                        if (inboxCountResp.has_unviewed_posts) defaultPath = DefaultLoggedinUserPath;
                        else if (inboxCountResp.has_posts) defaultPath = Paths.dashboard;
                        else defaultPath = Paths.resources;
                    } else defaultPath = Paths.landing;
                } catch (error) {}
                if (sessionStorage.getItem("popup_mode") === "true") redirectPath = Paths.createdVideos + "?popup_mode=true";
                const path = isUserWhiteListed
                    ? redirectPath === "/"
                        ? defaultPath
                        : redirectPath
                    : (redirectPath || "").startsWith(Paths.dashboard)
                    ? Paths.landing
                    : redirectPath;

                if (userData.mfaEnabled) {
                    sessionStorage.setItem("redirectPath", path);
                    this.props.navigate("/mfa/validate", { replace: true });
                } else {
                    await NotificationService.triggerRegistration();
                    this.props.navigate(path, { replace: true });
                    pushTagEvent("e_logged_in", { email: userData.email, account_type: userData.account_type });
                    identifyUserSession({ userId: userData.userId, email: userData.email, name: userData.name, isInternal: userData.isInternal });
                }
            }
        }
    };

    render() {
        if (this.state.loading) {
            return <Progress />;
        }
        if (this.state.securityError) {
            return <CookiesDisabledPopup />;
        }
        const authData: AuthData = {
            signIn: this.signIn,
            signOut: this.signOut,
            setUserData: this.setLoggedInUser,
            keys: this.state.keys,
            retrieveUserDataFromTokenAndRedirect: this.retrieveUserDataFromTokenAndRedirect,
            user: this.state.user,
            setWhitelisted: this.setWhitelisted,
            checkEmailWhitelisted: this.checkEmailWhitelisted,
            isTrialUser: this.state.isTrialUser,
            hasTrialEnded: this.state.hasTrialEnded,
            isWhitelisted: this.state.isWhitelisted,
            trialDaysLeft: this.state.trialDaysLeft
        };
        return <AuthContext.Provider value={authData}>{this.props.children}</AuthContext.Provider>;
    }
}

export const WrappedAuthProvider = (props: any) => {
    const navigate = useNavigate();
    return <AuthProvider navigate={navigate}>{props.children}</AuthProvider>;
};

export function useAuth() {
    return useContext(AuthContext);
}
