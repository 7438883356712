import { createContext } from "react";
import { IUserInfo } from "../Interfaces/IUserInfo";
import { AuthData } from "./AuthProvider";

const defaultState: AuthData = {
    signIn: () => {},
    signOut: () => {},
    setWhitelisted: () => {},
    checkEmailWhitelisted: () => {},
    setUserData: (userData: IUserInfo) => {},
    keys: undefined,
    retrieveUserDataFromTokenAndRedirect: async (token: any) => {},
    user: undefined,
    isTrialUser: false,
    hasTrialEnded: false,
    trialDaysLeft: 0,
    isWhitelisted: false
};

export const AuthContext = createContext<AuthData>(defaultState);
