import { FC, MouseEventHandler, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
import { Tabs, Tab, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { AuthContext } from "../../Contexts/AuthContext";
import MobileNavLinks from "./MobileNavLinks";
import { Desktop, Mobile } from "../../Common/Responsive";
import { NavbarTab } from "../Dashboard/NavBarTabs";
import { Paths } from "../../Common/AppRoutes";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import HttpService from "../../Services/HttpService";
import { ITimeSavedData } from "../../Interfaces/ITimeSavedData";
import { checkPathMatches, hasUserTrialEnded, userTrialDaysAboutToEnd, pushTagEvent, showUpgradeButton, hasCoBrandLogo } from "../../Common/Helper";
import { hasEmailUserTrialEnded, showWarningTrialStrip, getNavHeight } from "../../Common/Helper";
import NavLinks from "./NavLinks";
import Logo from "./Logo";
import CoBrandLogo from "./CoBrandLogo";

export const NavType = {
    default: 0,
    forgotPassword: 1
};

const NavBarContainer = styled.nav`
    color: white;
    justify-content: space-between;
    display: flex;
    width: 100%;
`;

const LeftSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CenterSection = styled.div`
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;
const RightSection = styled.div`
    display: block;
`;

export interface NavbarProps {
    theme?: string;
    disableTimeSavings?: boolean;
    breadcrumb?: number;
    maxBreadcrumb?: number;
    areFutureBreadcrumbsHot?: boolean;
    showBackButton?: boolean;
    coBrandLogo?: string;
    coBrandNav?: string;
    hideLoggedInUserCoBrandLogo?: boolean;
    navbarTabs?: NavbarTab[];
    selectedTabIndex?: number;
    showDashboardView?: boolean;
    hideCreateEmovidButton?: boolean;
    hideCreateEmovidButtonInMenu?: boolean;
    hideMobileNavBarLinks?: boolean;
    disableSignup?: boolean;
    popupModeTitle?: string;
    disableBreadcrumbs?: boolean;
    onBackClick?: MouseEventHandler<HTMLButtonElement>;
    onStepClicked?: (step: number) => void;
}

const NavBar: FC<NavbarProps> = (props: NavbarProps) => {
    const authContext = useContext(AuthContext);
    const [timeSavedData, setTimeSavedData] = useState<ITimeSavedData>(JSON.parse(sessionStorage.getItem("savedTime") || "{}"));
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const warningStripRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (warningStripRef.current) {
            document.documentElement.style.setProperty("--warning-strip-height", `${warningStripRef.current.offsetHeight}px`);
        }
    }, [warningStripRef]);

    useEffect(() => {
        if (sessionStorage.getItem("savedTime")) handleTimeSavedData(JSON.parse(sessionStorage.getItem("savedTime") || "{}"));
        else if (authContext.user?.userId)
            HttpService.get(`/v1/dashboard/time/saved`)
                .then(response => handleTimeSavedData(response))
                .catch(error => console.log(error));
        else setTimeSavedData({ status: "", message: "", total_time_saved: 0, weekly_time_saved: 0 });
    }, [authContext.user]);

    const handleTimeSavedData = (data: any) => {
        sessionStorage.setItem("savedTime", JSON.stringify(data));
        setTimeSavedData(data);
    };

    const handleTabChange = (e: any, index: number) => {
        navigate(((props.navbarTabs || [])[index] || {}).path || Paths.dashboard);
    };

    const handleStepClick = (step: number) => {
        if (props.breadcrumb !== step) props.onStepClicked?.(step);
    };

    const upgradeClicked = () => {
        pushTagEvent("e_btn_plan_upgrade", { source: "banner" });
        navigate(Paths.trialOffer + "?utm_source=upgrade_cta&utm_medium=banner");
    };

    const shouldShowTimeSaved = () => {
        let allowedPaths = [Paths.dashboard, Paths.myInbox, Paths.persona, Paths.createdVideos, Paths.viewEmovid, Paths.myAccount];
        return checkPathMatches(allowedPaths, location.pathname); // && isEmovidDomainUser(authContext.user?.email);
    };

    const isRecordingFlow = (): boolean => {
        return location.pathname.endsWith("/record") || location.pathname.endsWith("/edit-video") || location.pathname.endsWith("/preview");
    };

    const getLogoWidth = () => {
        if (showMiniLogo()) return "35px";
        return "auto";
    };

    const showMiniLogo = () => {
        return isMobile && isRecordingFlow();
    };

    const showCoBrandLogo = () => {
        if (showMiniLogo()) return false;
        return hasCoBrandLogoUrl();
    };

    const hasCoBrandLogoUrl = () => {
        if (props.hideLoggedInUserCoBrandLogo) return !!props.coBrandLogo;
        return hasCoBrandLogo(authContext);
    };

    const renderTrialWarning = () => (
        <>
            {(hasUserTrialEnded(authContext) || (!authContext.user?.userId && hasEmailUserTrialEnded())) && (
                <div className="warning-strip expired p-2 mb-2 text-center w-100" ref={warningStripRef}>
                    Your trial has ended.
                    <Tooltip
                        title="Your Emovids have been saved and can still be accessed. You do not have access to create new Emovids, but you can still respond to received Emovids."
                        enterTouchDelay={0}
                    >
                        <HelpOutlineRoundedIcon />
                    </Tooltip>
                    {showUpgradeButton() && (isDesktop || isTablet) && <>&nbsp;To continue sending Emovids, get full access</>}
                    {showUpgradeButton() && (
                        <button className="ms-2 btn btn-outline-dark" onClick={upgradeClicked}>
                            Upgrade Now
                        </button>
                    )}
                </div>
            )}
            {userTrialDaysAboutToEnd(authContext) && (
                <div className={"warning-strip p-2 mb-2 text-center w-100 " + (authContext.trialDaysLeft < 4 ? "about-to-expire" : "")} ref={warningStripRef}>
                    {authContext.trialDaysLeft === 0 ? `Your trial ends today` : `Your trial ends in ${authContext.trialDaysLeft} day${authContext.trialDaysLeft > 1 ? "s" : ""}`}.
                    {showUpgradeButton() && (isDesktop || isTablet) && <>&nbsp;For continued access&nbsp;</>}
                    {showUpgradeButton() && (
                        <button className="ms-2 btn btn-outline-dark" onClick={upgradeClicked}>
                            Upgrade Now
                        </button>
                    )}
                </div>
            )}
        </>
    );

    if (searchParams.get("popup_mode") === "true") {
        return (
            <>
                {props.theme === "dark" && <div style={{ height: getNavHeight(authContext) }}></div>}
                <NavBarContainer
                    className={["navbar fixed-top", props.theme === "dark" ? "shadow dark-theme" : "", showWarningTrialStrip(authContext) ? "pt-0" : ""].join(" ")}
                    style={props.theme !== "dark" ? { background: "linear-gradient(rgb(91, 23, 81), rgba(91, 23, 81, 0.6), transparent)" } : { background: "#4a4a4a" }}
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById("close-filter-btn")?.click();
                    }}
                >
                    {showWarningTrialStrip(authContext) && renderTrialWarning()}
                    <div id="nav-bar" className="position-relative container pt-2 pb-2">
                        <LeftSection>
                            <div style={{ width: getLogoWidth() }} className={props.theme === "dark" ? "overflow-hidden" : ""}>
                                <Logo className={props.theme === "dark" ? "" : "svg-shadow"} theme={props.theme} />
                            </div>
                            {showCoBrandLogo() && (
                                <>
                                    <div className="vertical-divider ms-2 me-2" style={{ height: "30px" }}></div>
                                    <Link
                                        to={props.coBrandNav || authContext.user?.coBrandNavUrl || "/"}
                                        style={{ width: getLogoWidth() }}
                                        className={props.theme === "dark" ? "overflow-hidden" : ""}
                                    >
                                        <CoBrandLogo className={props.theme === "dark" ? "" : "svg-shadow"} logoUrl={props.coBrandLogo || authContext.user?.coBrandLogoUrl || ""} />
                                    </Link>
                                </>
                            )}
                        </LeftSection>
                        <CenterSection>
                            {(props.breadcrumb || 0) > 1 && props.maxBreadcrumb && (
                                <Tooltip title="Previous step">
                                    <IconButton disabled={(props.breadcrumb || 0) <= 1 || props.disableBreadcrumbs} onClick={() => handleStepClick((props.breadcrumb || 0) - 1)}>
                                        <KeyboardArrowLeftRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.maxBreadcrumb &&
                                [...Array(props.maxBreadcrumb)].map((index: any, i: any) => (
                                    <button
                                        key={i}
                                        className={
                                            "btn btn-link number-breadcrumb" +
                                            (i + 1 <= (props.breadcrumb || 0) ? " elapsed clickable " : props.areFutureBreadcrumbsHot ? " clickable " : "") +
                                            (props.breadcrumb === i + 1 ? " current " : "")
                                        }
                                        disabled={props.disableBreadcrumbs}
                                        onClick={() => handleStepClick(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                            {props.maxBreadcrumb && (props.breadcrumb || 0) < props.maxBreadcrumb && (
                                <Tooltip title="Next step">
                                    <IconButton
                                        disabled={(props.areFutureBreadcrumbsHot ? (props.breadcrumb || 0) >= props.maxBreadcrumb : true) || props.disableBreadcrumbs}
                                        onClick={() => handleStepClick((props.breadcrumb || 0) + 1)}
                                    >
                                        <KeyboardArrowRightRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.navbarTabs && (
                                <div className="navbar-tabs-container">
                                    <Tabs value={props.selectedTabIndex} onChange={handleTabChange} aria-label="Dashboard tabs">
                                        {props.navbarTabs.map((navbarTab: NavbarTab, i: any) => (
                                            <Tab label={navbarTab.title} key={i} />
                                        ))}
                                    </Tabs>
                                </div>
                            )}
                            {!isRecordingFlow() && <div className="h5">{props.popupModeTitle || ""}</div>}
                        </CenterSection>
                        <RightSection></RightSection>
                    </div>
                </NavBarContainer>
                {props.popupModeTitle && isRecordingFlow() && (
                    <div className="container text-center">
                        <div className="p-3 h5">{props.popupModeTitle}</div>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {props.theme === "dark" && <div style={{ height: getNavHeight(authContext) }}></div>}
            <NavBarContainer
                className={["navbar fixed-top", props.theme === "dark" ? "shadow dark-theme" : "", showWarningTrialStrip(authContext) ? "pt-0" : ""].join(" ")}
                style={props.theme !== "dark" ? { background: "linear-gradient(rgb(91, 23, 81), rgba(91, 23, 81, 0.6), transparent)" } : { background: "#4a4a4a" }}
                onClick={e => {
                    e.stopPropagation();
                    document.getElementById("close-filter-btn")?.click();
                }}
            >
                <div style={{ height: "env(safe-area-inset-top)" }}>&nbsp;</div>
                {showWarningTrialStrip(authContext) && renderTrialWarning()}
                <div id="nav-bar" className="position-relative container pt-2 pb-2">
                    <LeftSection>
                        {props.showBackButton && (
                            <button className="btn btn-link mt-2 text-white" onClick={props.onBackClick || undefined}>
                                <i className="fa-solid fa-arrow-left me-2"></i>BACK
                            </button>
                        )}
                        {!props.showBackButton && (
                            <Link
                                to={isWhitelistedUser(authContext) ? Paths.dashboard : Paths.landing + "?show_home=true"}
                                style={{ width: getLogoWidth() }}
                                className={props.theme === "dark" ? "overflow-hidden" : ""}
                            >
                                <Logo className={props.theme === "dark" ? "" : "svg-shadow"} theme={props.theme} />
                            </Link>
                        )}
                        {!props.showBackButton && showCoBrandLogo() && (
                            <>
                                <div className="vertical-divider ms-2 me-2" style={{ height: "30px" }}></div>
                                <Link
                                    to={props.coBrandNav || authContext.user?.coBrandNavUrl || "/"}
                                    style={{ width: getLogoWidth() }}
                                    className={props.theme === "dark" ? "overflow-hidden" : ""}
                                >
                                    <CoBrandLogo className={props.theme === "dark" ? "" : "svg-shadow"} logoUrl={props.coBrandLogo || authContext.user?.coBrandLogoUrl || ""} />
                                </Link>
                            </>
                        )}
                    </LeftSection>
                    <CenterSection>
                        {(props.breadcrumb || 0) > 1 && props.maxBreadcrumb && (
                            <Tooltip title="Previous step">
                                <IconButton disabled={(props.breadcrumb || 0) <= 1 || props.disableBreadcrumbs} onClick={() => handleStepClick((props.breadcrumb || 0) - 1)}>
                                    <KeyboardArrowLeftRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {props.maxBreadcrumb &&
                            [...Array(props.maxBreadcrumb)].map((index: any, i: any) => (
                                <button
                                    key={i}
                                    className={
                                        "btn btn-link number-breadcrumb" +
                                        (i + 1 <= (props.breadcrumb || 0) ? " elapsed clickable " : props.areFutureBreadcrumbsHot ? " clickable " : "") +
                                        (props.breadcrumb === i + 1 ? " current " : "")
                                    }
                                    disabled={props.disableBreadcrumbs}
                                    onClick={() => handleStepClick(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        {props.maxBreadcrumb && (props.breadcrumb || 0) < props.maxBreadcrumb && (
                            <Tooltip title="Next step">
                                <IconButton
                                    disabled={(props.areFutureBreadcrumbsHot ? (props.breadcrumb || 0) >= props.maxBreadcrumb : true) || props.disableBreadcrumbs}
                                    onClick={() => handleStepClick((props.breadcrumb || 0) + 1)}
                                >
                                    <KeyboardArrowRightRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {props.navbarTabs && (
                            <div className="navbar-tabs-container">
                                <Tabs value={props.selectedTabIndex} onChange={handleTabChange} aria-label="Dashboard tabs">
                                    {props.navbarTabs.map((navbarTab: NavbarTab, i: any) => (
                                        <Tab label={navbarTab.title} key={i} />
                                    ))}
                                </Tabs>
                            </div>
                        )}
                    </CenterSection>
                    <RightSection>
                        <Desktop>
                            <NavLinks
                                showDashboardView={props.showDashboardView}
                                hideCreateEmovidButton={props.hideCreateEmovidButton}
                                hideCreateEmovidButtonInMenu={props.hideCreateEmovidButtonInMenu}
                                disableSignup={props.disableSignup}
                                showTimeSaved={props.disableTimeSavings ? false : shouldShowTimeSaved()}
                                timeSavedData={timeSavedData}
                            />
                        </Desktop>
                        {!props.hideMobileNavBarLinks && (
                            <Mobile>
                                <MobileNavLinks
                                    showTrialExpiredWarning={showWarningTrialStrip(authContext)}
                                    theme={props.theme}
                                    hideCreateEmovidButtonInMenu={props.hideCreateEmovidButtonInMenu}
                                    disableSignup={props.disableSignup}
                                    hideLoggedInUserCoBrandLogo={props.hideLoggedInUserCoBrandLogo}
                                    coBrandLogo={props.coBrandLogo}
                                    coBrandNav={props.coBrandNav}
                                    hasCoBrandLogoUrl={hasCoBrandLogoUrl()}
                                />
                            </Mobile>
                        )}
                    </RightSection>
                </div>
            </NavBarContainer>
        </>
    );
};

export default NavBar;
