import { PushNotifications } from "@capacitor/push-notifications";
import { PushNotificationStatus } from "@emovid/payloads-library";
import HttpService from "./HttpService";
import { isNativePlatform } from "../Common/Helper";

export default class NotificationService {
    public static async saveToken(token: string, userId: string) {
        try {
            await HttpService.postFormData("/v1/notification/device/token/add", { token, user_id: userId });
            console.log(`Saved token ${token} for user ${userId}`);
        } catch (error) {
            console.error("Error saving token to server: " + error);
        }
    }

    public static async removeToken(token: string) {
        if (!token) return;
        try {
            await HttpService.postFormData("/v1/notification/device/token/delete", { token });
            console.log(`Removed token ${token}`);
        } catch (error) {
            console.error("Error removing token from server: " + error);
        }
    }

    public static async updateStatus(notificationId: string, status: PushNotificationStatus) {
        try {
            await HttpService.postFormData("/v1/notification/status/update", { id: notificationId, status });
        } catch (error) {
            console.error("Error updating notification status: " + error);
        }
    }

    public static async triggerRegistration() {
        if (isNativePlatform()) {
            console.log("Triggering push registration");
            await PushNotifications.register();
        }
    }
}
